<template>
  <!-- Modal para ver la información de los participantes -->
  <app-dialog
    :max-width="1200"
    v-model="showModalVer"
    title="Oferta presentada"
  >
    <template #content>
      <InformacionProvedor :providerData="proveedor" />
    </template>
    <template #actions>
      <div class="mt-3">
        <v-btn
          color="secondary"
          @click="showModalVer = false"
          class="mr-4"
          outlined
        >
          Cerrar
        </v-btn>
      </div>
    </template>
  </app-dialog>
</template>

<script>
import InformacionProvedor from "@/components/InfomacionProveedorComponent.vue";

export default {
  name: "ModalDetailProvComponent",

  components: {
    InformacionProvedor,
  },

  data: () => ({
    showModalVer: false,
    proveedor: {},
  }),

  methods: {
    async verParticipante(id_proveedor) {

      const { data, status } = await this.services.Paac.getMostrarProveedor(
        id_proveedor
      );


      if (status == 200) {
        this.proveedor = data;
        this.showModalVer = true;
      }
    },
  },
};
</script>
